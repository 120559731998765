<!-- 2023/09/05 张晓瑜新增合同备案 -->
<template>
    <r-e-dialog title="合同备案" :visible.sync="dialogVisible" show-footer top="5vh" width="1500px" append-to-body
                @click-submit="handleSubmitPublish" @click-cancel="handleCancel" @close="handleCancel">
        <el-button type="primary" size="small" @click="openApproval">发起审批</el-button>
        <r-e-table ref="filingTableRef" :dataRequest="getContractIsRegistered" :columns="filingColumns" :height="500"
                   :showPagination="false" notAuto>
            <el-table-column slot="toolbar" label="操作" width="150" fixed="right">
                <template slot-scope="{ row }">
                    <div class="table-tools">
                        <span class="table-btn" @click="reapproval(row)">重新审批</span>
                        <span class="table-btn" @click="queryContractAuditStatus(row)">审批状态</span>
                    </div>
                </template>
            </el-table-column>
        </r-e-table>
        <!-- 发起审批弹窗 -->
        <dialog-approval ref="dialogApproval" :currentHouse="currentHouse" @handleSearch="handleSearch"/>
    </r-e-dialog>
</template>

<script>
import {FilingColumns} from "@/views/rental-management/house-resource/data";
import {getContractIsRegistered, queryContractAuditStatus} from "@/api/contract";
import {MessageSuccess} from "@custom/message";

export default {
    name: "dialog-contract-filing",
    data() {
        return {
            active: 1,
            dialogVisible: false,
            filingColumns: FilingColumns(this),
            billData: null,
            detailsDialogVisible: false, //弹窗
            loadingOptions: {
                lock: true,
                text: "Loading...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)"
            },
        }
    },
    props: {
        currentHouse: {
            type: Object,
            default: () => ({})
        }
    },
    components: {
        dialogApproval: () => import("./dialog-approval"),
    },
    methods: {
        //打开合同备案列表弹框
        openDialog(data) {
            //获取合同uuid
            let {uuid} = data;
            this.uuid = uuid;
            //打开弹窗
            this.dialogVisible = true;
            //渲染完成后查询列表
            this.$nextTick(() => {
                this.handleSearch()
            });
        },

        //查询合同备案列表请求
        getContractIsRegistered(params) {
            params.contractUuid = this.uuid;
            return getContractIsRegistered(params);
        },

        //查询合同备案列表方法
        handleSearch() {
            this.$refs["filingTableRef"].getTableData(true);
        },

        //点击确认按钮事件
        handleSubmitPublish() {
            //关闭弹窗
            this.dialogVisible = false;
        },

        //点击取消按钮事件
        handleCancel() {
            //关闭弹窗
            this.dialogVisible = false;
        },

        // 点击发起审批事件
        openApproval() {
            //打开发起审批弹窗
            this.$refs["dialogApproval"].openDialog(this.uuid, 0);
        },

        //点击重新审批事件
        reapproval(data) {
            //打开发起审批弹窗
            this.$refs["dialogApproval"].openDialog(data.uuid, 1);
        },

        //点击审批状态事件
        async queryContractAuditStatus(data) {
            const that = this;
            const {loadingOptions} = that;
            let loading = that.$loading(loadingOptions);
            //调用接口查询审批状态
            const res = await queryContractAuditStatus({uuid: data.uuid}).finally(() => loading.close());
            if (res) {
                const {info} = res;
                //提示审批状态
                MessageSuccess(info.message);
            }
        }
    },
    watch: {}
}
</script>

<style scoped>

</style>